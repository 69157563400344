.titleContainer>h1 {
    text-align: left;
    font-size: 11vw;
    line-height: 12vw;
    font-family: var(--font-logo);
    color: var(--color-grey);
}

.bootCampText {
    font-size: 19vw;
    margin: 0 0 0 -.5vw;
}

.introContainer {
    width: 86%;
    margin: 25px 0 10px 8px;
    display: flex;
    flex-direction: column;
    line-height: 5vw;
    color: var(--color-light-grey);
    border-radius: .3rem;
    padding: .5rem 0;
    /* background-color: rgb(0 0 0 / 14%); */
}

.acbcSubTitle {
    font-size: 5.5vw;
    text-transform: uppercase;
    color: var(--color-gold);
    margin: 4px 0;
}

.introContainer>p,
.headerIntroParagraph,
.headerIntroParagraph2,
.headerSubParagraph {
    text-align: left;
    font-size: 4.5vw;
    font-family: var(--font-proxima);
    margin: -2px 0 0 0;
}

.headerIntroParagraph {
    padding: .5rem .5rem 3px 0;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(0px);
    margin: -10px 0 0 0;

}

.headerSubParagraph {
    margin: 7px 0 0 0;
}

.contentContainer {
    width: 100%;
    margin: -9vw 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.parentheses {
    font-family: sans-serif;
}

.readMoreButton {
    width: 40%;
    border: 0;
    border-radius: .4rem;
    text-transform: uppercase;
    margin: 5px 0 5px 0;
    background-color: var(--color-gold-orange);

    color: black;
    font-size: 4.2vw;
    letter-spacing: .1vw;
    padding: 2vw 0;
}

.readMoreButton:hover {
    background-color: white;
    color: rgba(0, 0, 0, 0.767);
    transition: 0.2s;
}

.mainContainer {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: start;
    background-color: black;
    background-image: url("../../assets/images/header/header-bkgd-mobile-rev5.jpg");
    background-size: cover;
    background-repeat: repeat-y;
}

.link {
    width: 10vw;
    top: 24px;
    position: absolute;
    right: 23px;
    filter: invert(.31);
}

.link:hover {
    filter: brightness(1.2);
    transition: 0.2s;
}

.boardStyle {
    display: none;
}

.brTag {
    display: block;
}

.logo {
    margin: 8px 0 0 12px;
    height: 83px;
}


.titleContainer {
    width: 100%;
}

.titleContainer>* {
    margin: 0;
    text-transform: uppercase;
    text-align: center;
}

.titleContainer>h3 {
    font-size: 5vw;
    margin: .5rem 0 0 0;
    color: var(--font-secondary);
}

.spanText {
    color: var(--color-gold)
}

.titleContainer>h2 {
    font-size: 7vw;
    color: var(--font-color-primary);
}

@media screen and (min-width:374px) {

    .introContainer {
        margin: 28px 0 0 0;
    }

    .mainContainer {
        background-position: center top -29px;
    }

    .readMoreButton {
        margin: 8px 0 8px 0;
    }

}

@media screen and (min-width: 388px) {
    /* .mainContainer {
        background-position: center top -110px;
    } */
}


@media screen and (min-width: 403px) {
    /* .mainContainer {
        background-position: center top -131px;
    } */
}

@media screen and (min-width: 414px) {
    .mainContainer {
        background-position: center top -47px;
    }
}

@media screen and (min-width: 455px) {
    /* .mainContainer {
        background-position: center top -152px;
    } */
}

@media screen and (min-width: 494px) {
    /* .mainContainer {
        background-position: center top -182px;
    } */
}


@media screen and (min-width: 542px) {
    /* .mainContainer {
        background-position: center top -212px;
    } */
}

@media screen and (min-width: 568px) {
    .mainContainer {
        background-position: center top -122px;
    }
}


@media screen and (min-width:600px) {
    .introContainer {
        margin: 98px 0 0 11px;
    }

    .headerSubParagraph, .headerIntroParagraph, .introContainer>p {
        margin: 5px 0 2vw 0;
    }

    .headerSubParagraph {
        margin: 14px 0 0 0;
    }

    .headerIntroParagraph {
        margin-bottom: 4vw;
    }

    .readMoreButton {
        width: 165px;
        padding: 2vw 0;
        font-size: 3.2vw;
        margin: -19px 0px 14px 0;
    }

    .link {
        top: 53px;
        right: 34px;
    }

    .logo {
        margin: 26px 0 -27px 30px;
        height: 127px;
    }


    .mainContainer {
        background-position: center top -17px;
    }

}

@media screen and (min-width:664px) {
    /* .mainContainer {
        background-position: center top -162px;
    } */
}

@media screen and (min-width:728px) {

    .contentWrapper {
        padding: 1rem;
    }

    .introContainer {
        margin: 0 0 92px 72px;
        position: relative;
        background: none;
        width: 100%
    }

    .headerSubParagraph, .headerIntroParagraph, .introContainer>p {
        font-size: 2.5vw;
        line-height: 2.5vw;
    }

    .introContainer>p {
        backdrop-filter: blur(2.5px);
        border-radius: .2rem;
        margin: 0;
        padding: 5px;
    }

    .missionText {
        width: 90%;
    }

    .headerIntroParagraph {
        line-height: 1px;
    }

    .contentContainer {
        width: 81%;
        position: relative;
        top: 75px;
    }

    .acbcSubTitle {
        font-size: 3.5vw;
        margin: 11px 0 0 6px;
    }

    .titleContainer>h1 {
        font-size: 8vw;
        line-height: 8.5vw;
    }

    .bootCampText {
        font-size: 12vw
    }

    .mainContainer {
        width: 100%;
        align-items: start;
        background-image: url("../../assets/images/header/header-backgd-desktop.jpg");
        background-size: cover;
        background-position: center left -729px;
    }

    .boardStyle {
        width: 80vw;
        position: absolute;
        right: -410px;
        display: block;
        background-image: url("../../assets/images/header/clipboard.png");
        width: 100px;
        height: 100px;
    }

    .brTag {
        display: none;
    }


    .link {
        width: 5vw;
        top: 58px;
        right: 23px;
        filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.507));
    }



    .logo {
        margin: 32px 0 0 17px;
        height: 101px;
    }

    .readMoreButton {
        font-size: 1.51vw;
        padding: 1.1vw 0;
        width: 62%;
        margin: 18px 0 27px 0;
    }

}

@media screen and (min-width: 800px) {

    .boardStyle {
        right: -402px;
        width: 75vw;
    }

    .mainContainer {
        background-position: center left -759px;
    }
}

@media screen and (min-width:1024px) {
    .boardStyle {
        right: -550px;
        width: 770px;
        top: 61px;
    }

    .titleContainer>h1 {
        font-size: 84px;
        line-height: 88px;
        margin: 30px 0 0 0;
    }

    .bootCampText {
        font-size: 124px;
    }

    .acbcSubTitle {
        font-size: 22.5px;
    }

    .headerSubParagraph, .headerIntroParagraph, .introContainer>p {
        font-size: 24.5px;
        line-height: 22.5px;
    }

    .headerIntroParagraph {
        padding: 0;
    }

    .introContainer {
        margin: 0 0 132px 75px;
    }

    .mainContainer {
        background-position: center left -909px;
    }


}

@media screen and (min-width: 1100px) {
    .mainContainer {


        background-size: cover;
        background-position: center;
    }
}

@media screen and (min-width: 1200px) {
    .introContainer {
        max-width: 1200px;
    }

    .titleContainer>h1 {
        font-size: 114px;
        line-height: 115px;
        margin: 65px 0 0 0;
    }

    .bootCampText {
        font-size: 169px;
    }

    .link {
        width: 60px;
    }

    .contentContainer {
        margin: -135px 0 0 0;
    }

    .mainContainer {
        background-position: center left -819px;
    }
}


@media screen and (min-width: 1300px) {
    .boardStyle {
        right: -414px;
        width: 727px;
        top: 31px;
    }
}


@media screen and (min-width: 1500px) {
    .boardStyle {
        right: -284px;
        width: 727px;
    }
}