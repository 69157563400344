.navBar {
   background-color: #a6a0a0;
}

.navBarBrand {
   color: white !important;
}

.containerNav {
   display: flex;
   flex-direction: row-reverse;
}

a:hover {
   font-weight: 550 !important;
}