@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;1,400&family=Merriweather:wght@400;700&family=Montserrat:ital,wght@0,100;0,300;0,500;1,100&family=Roboto:wght@100;300;400;500;700&display=swap');

.cardWrapper {
  padding: 1.3rem 1.3rem 3rem 1.3rem;
  border-radius: 0;
  background-color: #000209;
  /* background-image: url("../../assets/desktop/contact/background.jpg"); */
  background-size: cover;
  display: flex;
  justify-content: center;
  height: 100vh;
}

.innerCardWrapper {
  text-align: center;
  width: 100rem;
}

.btn {
  color: white;
  font-family: arial;
  background-color: #a98519;
  border-radius: 0;
  border: none;
  width: 80%;
  margin-top: .5rem;
  transition: .5s;
  padding: 1rem 0;
}

.btn:hover {
  background-color: #d1b918;
  border: none;
  color: white;
  transform: scale(80%);
  box-shadow: none;
  box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.286);
}

.cardBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1.5rem;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.5206874986322654) 125%,
      rgba(2, 0, 36, 0) 100%);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.085);
  backdrop-filter: blur(1.52px);
  border-radius: .3rem;
}

.cardBody h2 {
  font-size: 1.1rem;
  margin-top: 1rem;
}

.cardBody hr {
  padding: 0;
  margin: .3rem;
  border: none;
}